<template>
    <div style="width: 100%">
        <div class="f-box">
            <div class="leave-item" v-for="(leaveItem,leaveItemIndex) in leaveArray" :key="leaveItemIndex">
                <div class="leave-item-head">
                    <div class="leave-item-head-left">请假{{leaveItemIndex+1}}</div>
                    <div class="leave-item-head-right">
                        <el-button type="primary" @click="handleAddLeave">添加</el-button>
                        <el-button type="primary" class="delete-leave-btn" @click="handleDeleteLeave(leaveItemIndex)" v-if="leaveItemIndex > 0">删除</el-button>
                    </div>
                </div>
                <div class="leave-item-body">
                    <el-form-item label="请假日期" required :label-width="labelWidth" class="leave-date-box">
                        <el-date-picker 
                            type="dates" 
                            value-format="yyyy-MM-dd"
                            v-model="ruleForm.leaveDate"
                            placeholder="请选择日期">
                        </el-date-picker>
                    </el-form-item>
                    <div class="flex-box" v-for="(timeItem,timeItemIndex) in leaveItem.time" :key="timeItem.id">
                        <label>
                            <span class="star">*&nbsp;</span>
                            <span class="text">时间段{{timeItemIndex+1}}</span>
                        </label>
                        <div class="form-item-box">
                            <el-form-item required :label-width="labelWidth" class="">
                                <el-time-picker
                                    v-model="leaveItem.time[timeItemIndex].value"
                                    :format="format"
                                    :value-format="format"
                                    is-range
                                    range-separator="至"
                                    start-placeholder="开始时间"
                                    end-placeholder="结束时间"
                                    placeholder="选择时间范围">
                                </el-time-picker>
                                <i class="el-icon-remove" v-show="leaveItem.time.length > 1" @click.stop="handleDeleteTimeItem(leaveItemIndex,timeItem.id)"></i>
                                <i class="el-icon-circle-plus" v-show="timeItemIndex == leaveItem.time.length-1" @click.stop="handleAddTimeItem(leaveItemIndex)"></i>
                            </el-form-item>
                        </div>
                    </div>
                    <el-form-item :label-width="labelWidth" label="请假时长" prop="desc">
                        <el-input placeholder="自动计算" readonly style="width: 100%" v-model="leaveItem.duration"></el-input>
                    </el-form-item>
                </div>
            </div>
        </div>
        <div class="dash-line"></div>
        <el-form-item :label-width="labelWidth" label="请假总时长" prop="desc">
            <el-input placeholder="自动计算" readonly style="width: 100%"  v-model="ruleForm.desc"></el-input>
        </el-form-item>
    </div>
</template>
<script>
import {
  DialogWrapper,
} from 'common-local';
export default {
    name: "Leave",
    components: {
        DialogWrapper
    },
    data() {
        return {
            labelWidth: "94px",
            ruleForm: {
                leaveDate:''
            },
            rules: {},
            leaveArray: [
                {
                    date: [],
                    time: [
                        {
                            id:'1',
                            value: ['', '']
                        },
                    ],
                    duration: ''
                }
            ],
            format:'HH:mm',
            addId: 2,
            showCalendarIndex: -1,
            dateList:{}
        };
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.initPageData();
        },
        initPageData() {
        },
        //添加请假
        handleAddLeave() {
            this.leaveArray.push({
                date: [],
                time: [['', '']],
                duration: ''
            })
            this.$forceUpdate();
        },
        handleDeleteLeave(index) {
            this.leaveArray.splice(index, 1);
        },
        /**
         * @description: 添加时间段
         * @param {*} findex
         * @return {*}
         * @author: 邵澳
         * @Date: 2023-11-01 14:09:07
         */                
        handleAddTimeItem(findex) {
            this.leaveArray[findex].time.push({
                id: this.addId,
                value: ['', '']
            })
            this.addId ++;
        },
        /**
         * @description: 删除时间段
         * @return {*}
         * @author: 邵澳
         * @Date: 2023-11-01 14:11:39
         */
        handleDeleteTimeItem(findex,sid) {
            this.leaveArray[findex].time = this.leaveArray[findex].time.filter(item => item.id !== sid)
        },
        calendarClick(e) {
            let date = e[1]
            if(this.dateList.hasOwnProperty(date)){
                delete this.dateList[date]
            }else{
                this.dateList[date] = '';
            }
            let arr = [];
            for (const key in this.dateList) {
                arr.push(key)
            }
            this.ruleForm.leaveDate = arr.join(',');
            this.$forceUpdate();
        }
    }
};
</script>
<style lang="scss" scoped>
.el-form-item {
    padding-right: 0 !important;
}
::v-deep{
    .f-box{
        .leave-item{
            .leave-item-head{
                padding-left: 23px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .leave-item-head-left{
                    font-weight: bolder;
                    font-size: 16px;
                }
                .leave-item-head-right{
                    .delete-leave-btn{
                        background-color: #ff9990;
                    }
                }
                    
            }
            .leave-item-body{
                .el-date-editor{
                    width: 100%;
                }
                .flex-box{
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                    label{
                        width: 94px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        .star{
                            color: #e63a2e;
                        }
                        .text{
                            color: #606266;
                        }
                    }
                    .form-item-box{
                        cursor: pointer;
                        flex: 1;
                        .el-form-item{
                            height: 36px !important;
                            margin-bottom: 0;
                            .el-form-item__content{
                                margin-left: 0px !important;
                                display: flex;
                                align-items: center;
                                .el-date-editor{
                                    padding-right: 0px !important;
                                    .el-range-separator{
                                        display: block;
                                        line-height: 27px;
                                        margin: 0 4px;
                                        padding: 0;
                                        width: 14px;
                                    }
                                    //针对火狐浏览器的样式
                                    @media screen and (min--moz-device-pixel-ratio:0) {
                                        .el-range-separator{
                                            line-height: 29px;
                                        }
                                    }
                                }
                            }
                        }
                        .el-icon-circle-plus,
                        .el-icon-remove{
                            margin-left: 5px;
                            font-size: 22px;
                            color: #5E96FF;
                        }
                        .el-icon-remove{
                            color: #F34536;
                        }
                    }
                }
                .leave-date-box{
                    .el-form-item__content{
                        display: block;
                    }
                }
            }
        }
    }
    .dash-line{
        margin-left: auto;
        width: 592px;
        height: 0;
        border-top: 1px dashed #DCDFE6;
    }
    .calendar{
        margin-top: 5px;
        border-radius: 4px;
        background-color: #fff;
        cursor: pointer;
        .el-form-item{
            width: 27%;
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;
        }
        .el-form-item:last-of-type{
            margin-right: 0;
            padding-right: 0;
            .el-form-item__content{
                text-align: right;
            }
        }
        .el-button{
            padding: 8px;
            span{
                font-size: 13px;
            }
        }
        .calendar-table{
            width: 100% !important;
            tbody{
                tr{
                    height: 50px !important;
                    td{
                        padding: 0 5px !important;
                    }
                }
                tr:last-of-type{
                    display: none !important;
                }
            }
        }
    }
}

</style>
